<template>
        <div class="main-display">
        <SideMenu/>
        <ContentDisplay>
            <slot>
                <p>大鳳可愛い過ぎ</p>
            </slot>
        </ContentDisplay>  
        </div>

</template>

<script>
import SideMenu from '@/components/SideMenu.vue';
import ContentDisplay from '@/components/ContentDisplay.vue';
import $ from 'jquery';
import {onMounted,nextTick} from 'vue';

export default {
    components: { 
        SideMenu,
        ContentDisplay,
    },
    setup() {
        onMounted(() => {
            nextTick(() => {
                $(".main-display").height($(".content-display-outer").height());
            });
        })
    },
    
}
</script>

  
<style scoped>
.main-display{
    min-height: 88vh;
}
</style>