import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/main/Gd_top.vue'
import goods_top from '@/main/Goods_top.vue'
import webapps_top from '@/main/WebApps_top.vue'
import articles_top from '@/main/Articles_top.vue'
import archives_top from '@/main/Archives_top.vue'
import KnightTour from '@/webapps/KnightTour.vue'
import archives_202405 from '@/archives/202405.vue'

//記事はここから下に
import kiji20240501 from '@/kiji/2024/202405/20240501.vue'
import kiji20240503 from '@/kiji/2024/202405/20240503.vue'

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/goods', name: 'goods', component: goods_top },
  { path: '/apps', name: 'apps', component: webapps_top },
  { path: '/kiji', name: 'articles', component: articles_top },
  { path: '/apps/KnightTour', name: 'KnightTour', component: KnightTour },
  { path: '/archives', name: 'archives_top', component: archives_top },
  { path: '/kiji/2024', name: 'archives_202405', component: archives_202405 },

  //記事はここから下に
  { path: '/kiji/2024/1', name: 'kiji20240501', component: kiji20240501 },
  { path: '/kiji/2024/2', name: 'kiji20240503', component: kiji20240503 },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
