<template>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=DotGothic16&display=swap" rel="stylesheet">

    <div class="header-pc">
        <div class="header-gd-main">
            <div class="header-gd-icon-outer"><router-link to="/"><img alt="gdIcon" src="../assets/gdIcon-removebg.png" class="header-gd-icon"></router-link></div>
            <div class="header-gd-text">greendisplay</div>
            <div class="header-gd-links">
                <router-link to="/goods"><img alt="headerGoods" src="../assets/header/tshirt.png" class="header-gd-icon-link"></router-link>
                <router-link to="/apps"><img alt="headerApps" src="../assets/header/folder.png" class="header-gd-icon-link"></router-link>
                <router-link to="/kiji"><img alt="headerKiji" src="../assets/header/book.png" class="header-gd-icon-link"></router-link>
                <div class="header-gd-blank"></div> 
                <div class="header-gd-blank"></div> 
            </div>
        </div>
    </div>

    <div class="header-sp">
        <div class="header-gd-main">
            <div class="header-gd-icon-outer"><router-link to="/"><img alt="gdIcon" src="../assets/gdIcon-removebg.png" class="header-gd-icon"></router-link></div>
            <div class="header-gd-text">greendisplay</div>
        </div>
        <div class="container">
            <input type="checkbox" id="actionMenuButton"/>
        
            <div class="actions-menu">
                <router-link to="/goods" class="btn btn--share btn-z">
                    <img class="header-sp-btn-img" alt="headerGoods" src="../assets/header/tshirt.png" >
                </router-link>
                <router-link to="/apps" class="btn btn--star btn-z">
                    <img class="header-sp-btn-img" alt="headerGoods" src="../assets/header/folder.png" >
                </router-link>
                <router-link to="/kiji" class="btn btn--comment btn-z">
                    <img class="header-sp-btn-img" alt="headerGoods" src="../assets/header/book.png" >
                </router-link>
                <label for="actionMenuButton" class="btn btn--large btn--menu"/>
                
            </div>
        </div>
    </div>

</template>

<script>
import router from '@/router';
export default {
    methods: {
        goodsPush(){
            router.push("/goods");
        }
    }
}
</script>
  
<style scoped>
@import './css/HeaderGD.css';

</style>