<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>
            <Title01>アーカイブ</Title01>
            <p><router-link to="/kiji/2024">{{MONTHS_LIST.YM202405}}</router-link></p>
        </MainDisplay>  

    </GeneralParent>

</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';
import Title01 from '@/components/Title01.vue';
import {MONTHS_LIST} from "@/CONST.ts"

export default {
    components: { 
        HeaderGD,
        GeneralParent,
        MainDisplay,
        Title01,
    },
    methods: {
        toYoutubeGD(){
            window.open("https://www.youtube.com/@greendisplay9337", '_blank');
        }
        
    },
    data(){
        return{
            MONTHS_LIST:MONTHS_LIST,
        }
    }
};
</script>

  
<style scoped>

</style>