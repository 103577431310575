<template>
  <router-view />
</template>

<script>

</script>

<style>
html {
  height: 100%;
}

body {
  height: 100%;
  background-color:aliceblue;
}
</style>
