<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>
            <Title01>記事のトップページです</Title01>
            <p><router-link to="/kiji/2024/1">{{KIJI_TITLE_LIST2024.KIJI001}}</router-link></p>
            <p><router-link to="/kiji/2024/2">{{KIJI_TITLE_LIST2024.KIJI002}}</router-link></p>
        </MainDisplay>

    </GeneralParent>
</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';
import Title01 from '@/components/Title01.vue';
import {KIJI_TITLE_LIST2024} from "@/kiji/js/titleCONST"

export default {
 components: { 
     HeaderGD,
     GeneralParent,
     MainDisplay,
     Title01,
    },
    data(){
        return{
            KIJI_TITLE_LIST2024: KIJI_TITLE_LIST2024,
        }
    }
};
</script>

  
<style scoped>

</style>