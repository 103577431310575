<template>
    <div class="kiji-title-main">
         <div class="kiji-title-outer">

            <div class="content-sp">
                <slot>
                    <p>大鳳</p>
                </slot>
            </div>
            <div class="content-pc">
                <slot>
                    <p>大鳳</p>
                </slot>
            </div>
         </div> 
     </div>
</template>

<script>

</script>


<style scoped>
@media(min-width:769px){
    .content-sp{
        display: none !important;
        /*pc幅で非表示になるため */
    }
    .kiji-title-main{
        margin-top: 5px;
        min-width:60vw;
        width: 90%;
        height:auto;
        padding: 0.5em 1em;
        background: rgba(172, 255, 172, 0.377);
        border-left: solid 10px rgba(0, 156, 0, 0.685);
    }

}
@media(max-width:768px){
    .content-pc{
        display: none !important;
        /*sp幅で非表示になるため */
    }
    .kiji-title-main{
        margin-top: 5px;
        width: 90%;
        height:auto;
        padding: 0.5em 1em;
        background: rgba(172, 255, 172, 0.377);
        border-left: solid 10px rgba(0, 156, 0, 0.685);
    }

}
 .kiji-title-outer{
    color: rgb(0, 0, 0);
    font-family:'Times New Roman', Times, serif, sans-serif;
    font-weight: 600;
    font-size: 2rem;
 }


</style>