<template>
    <div class="content-display-main">
        <div class="content-display-outer">
            <div class="content-sp">
                <slot>
                    <p>大鳳</p>
                </slot>
            </div>
            <div class="content-pc">
                <slot>
                    <p>大鳳</p>
                </slot>
            </div>
        </div> 
    </div>
</template>

<script>
</script>

  
<style scoped>
@media(min-width:769px){
    .content-sp{
        display: none !important;
        /*pc幅で非表示になるため */
    }
    .content-display-main{
        position: absolute;
        width: 72%;
        min-height: 88vh;
        left: 0;
    }
    .content-display-outer{
        position: absolute;
        left:10%;
        font-family:'Times New Roman', Times, "メイリオ", serif, sans-serif;
        font-weight: 400;
        font-size: 1rem;
        padding-right: 1%;
    }
}
@media(max-width:768px){
    .content-pc{
        display: none !important;
        /*sp幅で非表示になるため */
    }
    .content-display-main{
        position: absolute;
        width: 100%;
        min-height: 88vh;
        left: 0;
    }
    .content-display-outer{
        position: absolute;
        left:3%;
        right:3%;
        font-family:'Times New Roman', Times, "メイリオ", serif, sans-serif;
        font-weight: 400;
        font-size: 1rem;
    }
}
</style>