<template>
    <div>
        <slot class="general-parent-main">
            <p>Gotcha!</p>
        </slot>
    </div>

</template>

<script>
</script>

  
<style scoped>
.general-parent-main{
    position: relative;
}
</style>