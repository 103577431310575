<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>

            <h2>旅するナイト</h2>

            <h3 class="setsumei">チェスのナイトが全てのマスを一回ずつ通るパズル。全部赤くなったらクリア！</h3>
            <h3>マスの説明</h3>
            <h3 style="margin-left:30px; color:black;">黒のマス：ナイトの駒の現在位置</h3>
            <h3 style="margin-left:30px; color:red;">赤のマス：ナイトの駒が既に通過したマス</h3>
            <h3 style="margin-left:30px; color:orange;">オレンジのマス：ナイトが動けるマス</h3>
            <h3 style="margin-left:30px; color:blue;">青のマス：まだ通っていないマス</h3>

            <div id="LevelEight" class="container">
                <div id="eight" class="eight">
                    <h3>8×8盤面</h3>
                    <div id="1" class="parent">
                        <div id="11" class="child8 tile8" @click="touring8()" ></div>
                        <div id="12" class="child8 tile8" @click="touring8()" ></div>
                        <div id="13" class="child8 tile8" @click="touring8()" ></div>
                        <div id="14" class="child8 tile8" @click="touring8()" ></div>
                        <div id="15" class="child8 tile8" @click="touring8()" ></div>
                        <div id="16" class="child8 tile8" @click="touring8()" ></div>
                        <div id="17" class="child8 tile8" @click="touring8()" ></div>
                        <div id="18" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                    
                    <div id="2" class="parent">
                        <div id="21" class="child8 tile8" @click="touring8()" ></div>
                        <div id="22" class="child8 tile8" @click="touring8()" ></div>
                        <div id="23" class="child8 tile8" @click="touring8()" ></div>
                        <div id="24" class="child8 tile8" @click="touring8()" ></div>
                        <div id="25" class="child8 tile8" @click="touring8()" ></div>
                        <div id="26" class="child8 tile8" @click="touring8()" ></div>
                        <div id="27" class="child8 tile8" @click="touring8()" ></div>
                        <div id="28" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                    
                    <div id="3" class="parent">
                        <div id="31" class="child8 tile8" @click="touring8()" ></div>
                        <div id="32" class="child8 tile8" @click="touring8()" ></div>
                        <div id="33" class="child8 tile8" @click="touring8()" ></div>
                        <div id="34" class="child8 tile8" @click="touring8()" ></div>
                        <div id="35" class="child8 tile8" @click="touring8()" ></div>
                        <div id="36" class="child8 tile8" @click="touring8()" ></div>
                        <div id="37" class="child8 tile8" @click="touring8()" ></div>
                        <div id="38" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                    
                    <div id="4" class="parent">
                        <div id="41" class="child8 tile8" @click="touring8()" ></div>
                        <div id="42" class="child8 tile8" @click="touring8()" ></div>
                        <div id="43" class="child8 tile8" @click="touring8()" ></div>
                        <div id="44" class="child8 tile8" @click="touring8()" ></div>
                        <div id="45" class="child8 tile8" @click="touring8()" ></div>
                        <div id="46" class="child8 tile8" @click="touring8()" ></div>
                        <div id="47" class="child8 tile8" @click="touring8()" ></div>
                        <div id="48" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                    
                    <div id="5" class="parent">
                        <div id="51" class="child8 tile8" @click="touring8()" ></div>
                        <div id="52" class="child8 tile8" @click="touring8()" ></div>
                        <div id="53" class="child8 tile8" @click="touring8()" ></div>
                        <div id="54" class="child8 tile8" @click="touring8()" ></div>
                        <div id="55" class="child8 tile8" @click="touring8()" ></div>
                        <div id="56" class="child8 tile8" @click="touring8()" ></div>
                        <div id="57" class="child8 tile8" @click="touring8()" ></div>
                        <div id="58" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                    
                    <div id="6" class="parent">
                        <div id="61" class="child8 tile8" @click="touring8()"></div>
                        <div id="62" class="child8 go8" @click="touring8()"></div>
                        <div id="63" class="child8 tile8" @click="touring8()" ></div>
                        <div id="64" class="child8 tile8" @click="touring8()" ></div>
                        <div id="65" class="child8 tile8" @click="touring8()" ></div>
                        <div id="66" class="child8 tile8" @click="touring8()" ></div>
                        <div id="67" class="child8 tile8" @click="touring8()" ></div>
                        <div id="68" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                    
                    <div id="7" class="parent">
                        <div id="71" class="child8 tile8" @click="touring8()" ></div>
                        <div id="72" class="child8 tile8" @click="touring8()" ></div>
                        <div id="73" class="child8 go8" @click="touring8()"></div>
                        <div id="74" class="child8 tile8" @click="touring8()" ></div>
                        <div id="75" class="child8 tile8" @click="touring8()" ></div>
                        <div id="76" class="child8 tile8" @click="touring8()" ></div>
                        <div id="77" class="child8 tile8" @click="touring8()" ></div>
                        <div id="78" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                    
                    <div id="8" class="parent">
                        <div id="81" class="child8 knight8now" @click="touring8()" ></div>
                        <div id="82" class="child8 tile8" @click="touring8()" ></div>
                        <div id="83" class="child8 tile8" @click="touring8()" ></div>
                        <div id="84" class="child8 tile8" @click="touring8()" ></div>
                        <div id="85" class="child8 tile8" @click="touring8()" ></div>
                        <div id="86" class="child8 tile8" @click="touring8()" ></div>
                        <div id="87" class="child8 tile8" @click="touring8()" ></div>
                        <div id="88" class="child8 tile8" @click="touring8()" ></div>
                    </div>
                </div>
            </div>

            <div id="LevelFive" class="container">
                <div id="five" class="five">
                    <h3>5×5盤面</h3>
                    <div  class="parent">
                        <div id="101" class="child5 tile5" @click="touring5()" ></div>
                        <div id="102" class="child5 tile5" @click="touring5()" ></div>
                        <div id="103" class="child5 tile5" @click="touring5()" ></div>
                        <div id="104" class="child5 tile5" @click="touring5()" ></div>
                        <div id="105" class="child5 tile5" @click="touring5()" ></div>
                    </div>
                    
                    <div  class="parent">
                        <div id="201" class="child5 tile5" @click="touring5()" ></div>
                        <div id="202" class="child5 tile5" @click="touring5()" ></div>
                        <div id="203" class="child5 tile5" @click="touring5()" ></div>
                        <div id="204" class="child5 tile5" @click="touring5()" ></div>
                        <div id="205" class="child5 tile5" @click="touring5()" ></div>
                    </div>
                    
                    <div  class="parent">
                        <div id="301" class="child5 tile5" @click="touring5()" ></div>
                        <div id="302" class="child5 go5" @click="touring5()"></div>
                        <div id="303" class="child5 tile5" @click="touring5()" ></div>
                        <div id="304" class="child5 tile5" @click="touring5()" ></div>
                        <div id="305" class="child5 tile5" @click="touring5()" ></div>
                
                    </div>
                    
                    <div  class="parent">
                        <div id="401" class="child5 tile5" @click="touring5()" ></div>
                        <div id="402" class="child5 tile5" @click="touring5()" ></div>
                        <div id="403" class="child5 go5" @click="touring5()"></div>
                        <div id="404" class="child5 tile5" @click="touring5()" ></div>
                        <div id="405" class="child5 tile5" @click="touring5()" ></div>
                    </div>
                    
                    <div  class="parent">
                        <div id="501" class="child5 knight5now" @click="touring5()" ></div>
                        <div id="502" class="child5 tile5" @click="touring5()" ></div>
                        <div id="503" class="child5 tile5" @click="touring5()" ></div>
                        <div id="504" class="child5 tile5" @click="touring5()" ></div>
                        <div id="505" class="child5 tile5" @click="touring5()" ></div>
                    </div>
                </div>
            </div>

            <div id="LevelSix" class="container">
                <div id="six" class="six">
                    <h3>6×6盤面</h3>
                    <div  class="parent">
                        <div id="1001" class="child6 tile6" @click="touring6()" ></div>
                        <div id="1002" class="child6 tile6" @click="touring6()" ></div>
                        <div id="1003" class="child6 tile6" @click="touring6()" ></div>
                        <div id="1004" class="child6 tile6" @click="touring6()" ></div>
                        <div id="1005" class="child6 tile6" @click="touring6()" ></div>
                        <div id="1006" class="child6 tile6" @click="touring6()" ></div>
                    </div>
                    
                    <div  class="parent">
                        <div id="2001" class="child6 tile6" @click="touring6()" ></div>
                        <div id="2002" class="child6 tile6" @click="touring6()" ></div>
                        <div id="2003" class="child6 tile6" @click="touring6()" ></div>
                        <div id="2004" class="child6 tile6" @click="touring6()" ></div>
                        <div id="2005" class="child6 tile6" @click="touring6()" ></div>
                        <div id="2006" class="child6 tile6" @click="touring6()" ></div>
                    </div>
                    
                    <div  class="parent">
                        <div id="3001" class="child6 tile6" @click="touring6()" ></div>
                        <div id="3002" class="child6 tile6" @click="touring6()" ></div>
                        <div id="3003" class="child6 tile6" @click="touring6()" ></div>
                        <div id="3004" class="child6 tile6" @click="touring6()" ></div>
                        <div id="3005" class="child6 tile6" @click="touring6()" ></div>
                        <div id="3006" class="child6 tile6" @click="touring6()" ></div>
                
                    </div>
                    
                    <div  class="parent">
                        <div id="4001" class="child6 tile6" @click="touring6()" ></div>
                        <div id="4002" class="child6 go6" @click="touring6()"></div>
                        <div id="4003" class="child6 tile6" @click="touring6()" ></div>
                        <div id="4004" class="child6 tile6" @click="touring6()" ></div>
                        <div id="4005" class="child6 tile6" @click="touring6()" ></div>
                        <div id="4006" class="child6 tile6" @click="touring6()" ></div>
                    </div>
                    
                    <div  class="parent">
                        <div id="5001" class="child6 tile6" @click="touring6()" ></div>
                        <div id="5002" class="child6 tile6" @click="touring6()" ></div>
                        <div id="5003" class="child6 go6" @click="touring6()"></div>
                        <div id="5004" class="child6 tile6" @click="touring6()" ></div>
                        <div id="5005" class="child6 tile6" @click="touring6()" ></div>
                        <div id="5006" class="child6 tile6" @click="touring6()" ></div>
                    </div>
                        <div  class="parent">
                        <div id="6001" class="child6 knight6now" @click="touring6()" ></div>
                        <div id="6002" class="child6 tile6" @click="touring6()" ></div>
                        <div id="6003" class="child6 tile6" @click="touring6()" ></div>
                        <div id="6004" class="child6 tile6" @click="touring6()" ></div>
                        <div id="6005" class="child6 tile6" @click="touring6()" ></div>
                        <div id="6006" class="child6 tile6" @click="touring6()" ></div>
                    </div>
                </div>
            </div>

            <input id="refresh" type="button" onclick="location.reload();" value="最初から" />

        </MainDisplay>  
    </GeneralParent>
</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';


//各盤面のナイト移動回数をカウントする変数
var tileCount8 = 0;
var tileCount5 = 0;
var tileCount6 = 0;

export default {
    components: { 
        HeaderGD,
        GeneralParent,
        MainDisplay,
    },
    data() {
        return {
            htmlContent: "",
        };
    },


    methods: {
        /**
         * 旅するナイト処理
         */

        touring8() {
            document.body.addEventListener( 'click', function(event) {
                let class_name = event.target.className.split(' ');
                if(class_name[1] == "go8"){
                    //移動が発生したら移動回数を+1
                    tileCount8++;
                    
                    // ID上限値と下限値、IDの桁数（使用する桁数）、最大移動回数の設定
                    var upperLimitNum = 9;
                    //var lowerLimitNum = 0;
                    var idCalcNum = 10;
                    var correctJudge = 63;

                    // 要素IDを取得する
                    var e = e || window.event;
                    var elem = e.target || e.srcElement;
                    var elemId = elem.id;
                    // IDを整数に変換
                    var idNum = Number(elemId);
                    
                    //IDの二桁目と一桁目の値を取得
                    var twoDigit = Math.floor(idNum / idCalcNum);
                    var oneDigit = idNum - twoDigit * idCalcNum;
                    
                    var goArray = [];
                    var pushNum = 0;
                    
                    //次の移動可能先を決定
                    if((twoDigit - 2) > 0 && (oneDigit - 1) > 0){
                        pushNum = (twoDigit - 2) * idCalcNum + (oneDigit - 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 2) > 0 && (oneDigit + 1) < upperLimitNum){
                        pushNum = (twoDigit - 2) * idCalcNum + (oneDigit + 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 1) > 0 && (oneDigit - 2) > 0){
                        pushNum = (twoDigit - 1) * idCalcNum + (oneDigit - 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 1) > 0 && (oneDigit + 2) < upperLimitNum){
                        pushNum = (twoDigit - 1) * idCalcNum + (oneDigit + 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 1) < upperLimitNum && (oneDigit - 2) > 0){
                        pushNum = (twoDigit + 1) * idCalcNum + (oneDigit - 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 1) < upperLimitNum && (oneDigit + 2) < upperLimitNum){
                        pushNum = (twoDigit + 1) * idCalcNum + (oneDigit + 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 2) < upperLimitNum && (oneDigit - 1) > 0){
                        pushNum = (twoDigit + 2) * idCalcNum + (oneDigit - 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 2) < upperLimitNum && (oneDigit + 1) < upperLimitNum){
                        pushNum = (twoDigit + 2) * idCalcNum + (oneDigit + 1);
                        goArray.push(String(pushNum));
                    }
                    
                    //移動前にナイトがいた場所を通過済みタイルに設定
                    let oldKnightClass = document.getElementsByClassName('knight8now');
                    Array.prototype.forEach.call(oldKnightClass, function (element) {
                        element.classList.remove('knight8now');
                        element.setAttribute('class', 'child8 knight8');
                    });
                    
                    //通過していないタイルのクラスをリセット
                    let childClass = document.getElementsByClassName('child8');
                    Array.prototype.forEach.call(childClass, function (element) {
                        element.classList.remove('go8');
                        element.classList.remove('tile8');
                        element.removeAttribute('onclick');
                        if(!(element.classList.contains('knight8'))){
                            element.setAttribute('class','child8 tile8');
                        }
                    });
                    
                    //移動先のタイルをナイトの現在位置に設定。規定回数の移動を行っていた場合はゲームクリア
                    let knightId = document.getElementById(elemId);
                    knightId.classList.remove('tile8');
                    if(tileCount8 === correctJudge){
                        knightId.setAttribute('class', 'child8 knight8');
                        
                        var newElement = document.createElement("p"); // p要素作成
                        var newContent = document.createTextNode("すごい！"); // テキストノードを作成
                        newElement.appendChild(newContent); // p要素にテキストノードを追加
                        newElement.setAttribute("id","clear8"); // p要素にidを設定
                        newElement.setAttribute("class","game-clear"); // p要素にclassを設定
                        //盤面の上に文字を生成
                        var backDiv = document.getElementById("LevelEight");
                        // 子要素３への参照を取得
                        var eight = document.getElementById("eight");
                        // 追加
                        backDiv.insertBefore(newElement, eight.nextSibling);
                    }
                    else{
                        knightId.setAttribute('class', 'child8 knight8now');
                    }
                    
                    //次の移動可能先タイルと判断されたIDに移動可能タイルの設定
                    for(var i = 0; i < goArray.length; i++){
                        let goId= document.getElementById(String(goArray[i]));
                        if(goId.classList.contains('knight8')){
                            continue;
                        }
                        goId.classList.remove('tile8');
                        goId.setAttribute('class', 'child8 go8');
                    } 
                }
            });
        },

        touring5(){
            document.body.addEventListener( 'click', function(event) {
                let class_name = event.target.className.split(' ');
                if(class_name[1] == "go5"){
                    //移動が発生したら移動回数を+1
                    tileCount5++;
                    
                    // ID上限値と下限値、IDの桁数（使用する桁数）、最大移動回数の設定
                    var upperLimitNum = 6;
                    //var lowerLimitNum = 0;
                    var idCalcNum = 100;
                    var correctJudge = 24;
                    
                    /// 要素IDを取得する
                    var e = e || window.event;
                    var elem = e.target || e.srcElement;
                    var elemId = elem.id;
                    // IDを整数に変換
                    var idNum = Number(elemId);
                    
                    //IDの二桁目と一桁目の値を取得
                    var twoDigit = Math.floor(idNum / idCalcNum);
                    var oneDigit = idNum - twoDigit * idCalcNum;
                    
                    var goArray = [];
                    var pushNum = 0;
                    
                    //次の移動可能先を決定
                    if((twoDigit - 2) > 0 && (oneDigit - 1) > 0){
                        pushNum = (twoDigit - 2) * idCalcNum + (oneDigit - 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 2) > 0 && (oneDigit + 1) < upperLimitNum){
                        pushNum = (twoDigit - 2) * idCalcNum + (oneDigit + 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 1) > 0 && (oneDigit - 2) > 0){
                        pushNum = (twoDigit - 1) * idCalcNum + (oneDigit - 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 1) > 0 && (oneDigit + 2) < upperLimitNum){
                        pushNum = (twoDigit - 1) * idCalcNum + (oneDigit + 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 1) < upperLimitNum && (oneDigit - 2) > 0){
                        pushNum = (twoDigit + 1) * idCalcNum + (oneDigit - 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 1) < upperLimitNum && (oneDigit + 2) < upperLimitNum){
                        pushNum = (twoDigit + 1) * idCalcNum + (oneDigit + 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 2) < upperLimitNum && (oneDigit - 1) > 0){
                        pushNum = (twoDigit + 2) * idCalcNum + (oneDigit - 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 2) < upperLimitNum && (oneDigit + 1) < upperLimitNum){
                        pushNum = (twoDigit + 2) * idCalcNum + (oneDigit + 1);
                        goArray.push(String(pushNum));
                    }
                    
                    //移動前にナイトがいた場所を通過済みタイルに設定
                    let oldKnightClass = document.getElementsByClassName('knight5now');
                    Array.prototype.forEach.call(oldKnightClass, function (element) {
                        element.classList.remove('knight5now');
                        element.setAttribute('class', 'child knight5');
                    });
                    
                    //通過していないタイルのクラスをリセット
                    let childClass = document.getElementsByClassName('child5');
                    Array.prototype.forEach.call(childClass, function (element) {
                        element.classList.remove('go5');
                        element.classList.remove('tile5');
                        element.removeAttribute('onclick');
                        if(!(element.classList.contains('knight5'))){
                            element.setAttribute('class','child5 tile5');
                        }
                    });
                    
                    //移動先のタイルをナイトの現在位置に設定。規定回数の移動を行っていた場合はゲームクリア
                    let knightId = document.getElementById(elemId);
                    knightId.classList.remove('tile5');
                    if(tileCount5 === correctJudge){
                        knightId.setAttribute('class', 'child5 knight5');
                        
                        var newElement = document.createElement("p"); // p要素作成
                        var newContent = document.createTextNode("すごい！"); // テキストノードを作成
                        newElement.appendChild(newContent); // p要素にテキストノードを追加
                        newElement.setAttribute("id","clear5"); // p要素にidを設定
                        newElement.setAttribute("class","game-clear"); // p要素にclassを設定
                        //盤面の上に文字を生成
                        var backDiv = document.getElementById("LevelFive");
                        // 子要素３への参照を取得
                        var five = document.getElementById("five");
                        // 追加
                        backDiv.insertBefore(newElement, five.nextSibling);
                    }
                    else{
                        knightId.setAttribute('class', 'child5 knight5now');
                    }
                    
                    //次の移動可能先タイルと判断されたIDに移動可能タイルの設定
                    for(var i = 0; i < goArray.length; i++){
                        let goId= document.getElementById(String(goArray[i]));
                        if(goId.classList.contains('knight5')){
                            continue;
                        }
                        goId.classList.remove('tile5');
                        goId.setAttribute('class', 'child5 go5');
                    } 
                }
            });

        },

        touring6(){
            document.body.addEventListener( 'click', function(event) {
                let class_name = event.target.className.split(' ');
                if(class_name[1] == "go6"){
                    //移動が発生したら移動回数を+1
                    tileCount6++;
                    
                    // ID上限値と下限値、IDの桁数（使用する桁数）、最大移動回数の設定
                    var upperLimitNum = 7;
                    //var lowerLimitNum = 0;
                    var idCalcNum = 1000;
                    var correctJudge = 35;

                    /// 要素IDを取得する
                    var e = e || window.event;
                    var elem = e.target || e.srcElement;
                    var elemId = elem.id;
                    // IDを整数に変換
                    var idNum = Number(elemId);
                    
                    //IDの二桁目と一桁目の値を取得
                    var twoDigit = Math.floor(idNum / idCalcNum);
                    var oneDigit = idNum - twoDigit * idCalcNum;
                    
                    var goArray = [];
                    var pushNum = 0;
                    
                    //次の移動可能先を決定
                    if((twoDigit - 2) > 0 && (oneDigit - 1) > 0){
                        pushNum = (twoDigit - 2) * idCalcNum + (oneDigit - 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 2) > 0 && (oneDigit + 1) < upperLimitNum){
                        pushNum = (twoDigit - 2) * idCalcNum + (oneDigit + 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 1) > 0 && (oneDigit - 2) > 0){
                        pushNum = (twoDigit - 1) * idCalcNum + (oneDigit - 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit - 1) > 0 && (oneDigit + 2) < upperLimitNum){
                        pushNum = (twoDigit - 1) * idCalcNum + (oneDigit + 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 1) < upperLimitNum && (oneDigit - 2) > 0){
                        pushNum = (twoDigit + 1) * idCalcNum + (oneDigit - 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 1) < upperLimitNum && (oneDigit + 2) < upperLimitNum){
                        pushNum = (twoDigit + 1) * idCalcNum + (oneDigit + 2);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 2) < upperLimitNum && (oneDigit - 1) > 0){
                        pushNum = (twoDigit + 2) * idCalcNum + (oneDigit - 1);
                        goArray.push(String(pushNum));
                    }
                    
                    if((twoDigit + 2) < upperLimitNum && (oneDigit + 1) < upperLimitNum){
                        pushNum = (twoDigit + 2) * idCalcNum + (oneDigit + 1);
                        goArray.push(String(pushNum));
                    }
                    
                    //移動前にナイトがいた場所を通過済みタイルに設定
                    let oldKnightClass = document.getElementsByClassName('knight6now');
                    Array.prototype.forEach.call(oldKnightClass, function (element) {
                        element.classList.remove('knight6now');
                        element.setAttribute('class', 'child knight6');
                    });
                    
                    //通過していないタイルのクラスをリセット
                    let childClass = document.getElementsByClassName('child6');
                    Array.prototype.forEach.call(childClass, function (element) {
                        element.classList.remove('go6');
                        element.classList.remove('tile6');
                        element.removeAttribute('onclick');
                        if(!(element.classList.contains('knight6'))){
                            element.setAttribute('class','child6 tile6');
                        }
                    });
                    
                    //移動先のタイルをナイトの現在位置に設定。規定回数の移動を行っていた場合はゲームクリア
                    let knightId = document.getElementById(elemId);
                    knightId.classList.remove('tile6');
                    if(tileCount6 === correctJudge){
                        knightId.setAttribute('class', 'child6 knight6');
                        
                        var newElement = document.createElement("p"); // p要素作成
                        var newContent = document.createTextNode("すごい！"); // テキストノードを作成
                        newElement.appendChild(newContent); // p要素にテキストノードを追加
                        newElement.setAttribute("id","clear6"); // p要素にidを設定
                        newElement.setAttribute("class","game-clear"); // p要素にclassを設定
                        //盤面の上に文字を生成
                        var backDiv = document.getElementById("LevelSix");
                        // 子要素３への参照を取得
                        var six = document.getElementById("six");
                        // 追加
                        backDiv.insertBefore(newElement, six.nextSibling);
                    }
                    else{
                        knightId.setAttribute('class', 'child6 knight6now');
                    }
                    
                    //次の移動可能先タイルと判断されたIDに移動可能タイルの設定
                    for(var i = 0; i < goArray.length; i++){
                        let goId= document.getElementById(String(goArray[i]));
                        if(goId.classList.contains('knight6')){
                            continue;
                        }
                        goId.classList.remove('tile6');
                        goId.setAttribute('class', 'child6 go6');
                        goId.setAttribute('onclick', 'touring6()');
                    } 
                }
            });

        }

    },
};

</script>

  
<style scoped>
@import './css/KnightTour.css';
</style>