<template>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=DotGothic16&display=swap" rel="stylesheet">

    <p class="sidemenu-gd-element"><a @click="sidemenuClick(href,blank)">・{{ elementStr }}</a></p>

</template>

<script setup>
import router from '@/router';
import { defineProps } from 'vue';

defineProps({
    elementStr: {
        type:String,
        required:true,
        default:'大鳳好こ'
    },
    href:{
        type:String,
        required:false,
        default:'/'
    },
    blank:{
        type:Boolean,
        reduired:false,
        default:false
    }
});

function sidemenuClick(href,blank) {
    if(blank){
        window.open(href, '_blank');
    }
    else{
        router.push(href);
    }
}
</script>

  
<style scoped>
@import './css/SideMenu.css';
</style>