<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>
            <Title01>{{MONTHS_LIST.YM202405}}</Title01>
            <p><router-link to="/kiji/2024/1">{{KIJI_TITLE_LIST2024.KIJI001}}</router-link></p>
            <p><router-link to="/kiji/2024/2">{{KIJI_TITLE_LIST2024.KIJI002}}</router-link></p>
        </MainDisplay>  

    </GeneralParent>

</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';
import Title01 from '@/components/Title01.vue';
import {MONTHS_LIST} from "@/CONST.ts"
import {KIJI_TITLE_LIST2024} from "@/kiji/js/titleCONST"

export default {
    components: { 
        HeaderGD,
        GeneralParent,
        MainDisplay,
        Title01,
    },
    data(){
        return{
            MONTHS_LIST:MONTHS_LIST,
            KIJI_TITLE_LIST2024: KIJI_TITLE_LIST2024,
        }
    }
};
</script>

  
<style scoped>
</style>