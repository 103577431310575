export const MAIN_STR = {
    GOODS:"グッズ",
    APPS:"Webアプリ",
    ARTICLES:"記事",
    ARCHIVES:"アーカイブ",
    RECOMMENDATION:"おすすめ",
    GOODS_LIST:"グッズ一覧",
    APPS_LIST:"Webアプリ一覧",
    ARTICLES_LIST:"記事一覧",
    GOODS_RECOMMENDATION:"おすすめグッズ",
    APPS_RECOMMENDATION:"おすすめWebアプリ",
    ARTICLES_RECOMMENDATION:"おすすめ記事",
    NIBOSHIMARU:"煮干丸に行こう！",
}

export const ONUNUME_APPS = {
    APPS01:"ナイトツアー",
    APPS02:"魚編の漢字の画数",
    APPS03:"null",
}

export const MONTHS_LIST = {
    YM202405:"2024年5月",
    YM202406:"2024年6月",
    YM202407:"2024年7月",
    YM202408:"2024年8月",
    YM202409:"2024年9月",
    YM202410:"2024年10月",
    YM202411:"2024年11月",
    YM202412:"2024年12月",
}