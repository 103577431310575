<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>
            <img class="gd-top-img01" alt="gdIcon" src="../assets/gdIcon.png">
            <div class="gd-top-01">こんにちは。greendisplayです。</div>
            <div class="to-youtube-gd" @click="toYoutubeGD()">あんまり動いてないチャンネル</div>
            <p>↓記事のアーカイブ↓</p>
            <p><router-link to="/archives">アーカイブ</router-link></p>
        </MainDisplay>  

    </GeneralParent>

</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';

export default {
    components: { 
        HeaderGD,
        GeneralParent,
        MainDisplay,
    },
    methods: {
        toYoutubeGD(){
            window.open("https://www.youtube.com/@greendisplay9337", '_blank');
        }
        
    },
};
</script>

  
<style scoped>
.to-youtube-gd{
    text-decoration: underline;
    color: blue;
}
.to-youtube-gd:hover{
    cursor : pointer;
    text-decoration: underline;
    color: blue;
}
.gd-top-01{
    font-size: 2rem;
}

</style>