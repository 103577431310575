<template>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=DotGothic16&display=swap" rel="stylesheet">

    <div class="sidemenu-gd-main">
        <div class="sidemenu-gd-frame">
            <div>
                <div class="sidemenu-gd-subhead">{{MAIN_STR.APPS_RECOMMENDATION}}</div>
                <SideMenuElements :elementStr="ONUNUME_APPS.APPS01" href="/apps/KnightTour"></SideMenuElements>
                <SideMenuElements :elementStr="ONUNUME_APPS.APPS02" href="/"></SideMenuElements>
            </div>
            <div>
                <div class="sidemenu-gd-subhead">{{MAIN_STR.NIBOSHIMARU}}</div>
                <SideMenuElements elementStr="聖地" href="https://ramendb.supleks.jp/s/97221.html" :blank="true"></SideMenuElements>
            </div>

            <div>
                <div class="sidemenu-gd-subhead">{{MAIN_STR.ARCHIVES}}</div>
                <SideMenuElements elementStr="すべて" href="/archives"></SideMenuElements>
                <SideMenuElements :elementStr="MONTHS_LIST.YM202405" href="/kiji/2024"></SideMenuElements>
            </div>
        </div>
    </div>

</template>

<script>
import {MAIN_STR} from "@/CONST.ts"
import {ONUNUME_APPS} from "@/CONST.ts"
import {MONTHS_LIST} from "@/CONST.ts"
import SideMenuElements from '@/components/SideMenuElements.vue';

export default {
    components: { 
        SideMenuElements
    },
    data(){
        return{
            MAIN_STR: MAIN_STR,
            ONUNUME_APPS:ONUNUME_APPS,
            MONTHS_LIST:MONTHS_LIST,
        }
    }
}

</script>

  
<style scoped>
@import './css/SideMenu.css';

</style>