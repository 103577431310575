<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>
            <Title01>{{KIJI_TITLE_LIST2024.KIJI001}}</Title01>
            <p class="kiji-default-p">
                能登麻美子さん一筋、アダタラです。
            </p>
            <p class="kiji-default-p">
                2023年の年末のコミケで大鳳の色紙を買いまして、そろそろ自慢したくなりましたので写真を一枚。隣には大鳳本のついでに購入した潮ちゃんの色紙です。
            </p>
            <img class="kiji-default-img" alt="大鳳と潮" src="@/assets/img/taihouandushio.jpg"/>
            <p class="kiji-default-p">
                鳩守カンさんとひざかさんは定期的に大鳳ちゃんを供給してくださる素晴らしい絵師さんです。皆さんも推しましょう。
            </p>
            <p class="kiji-default-p">
                <a href="https://twitter.com/8x_1o">鳩守カンさんのTwitter</a>
            </p>
            <p class="kiji-default-p">
                <a href="https://twitter.com/hizaka12">ひざかさんのTwitter</a>
            </p>
        </MainDisplay>

    </GeneralParent>

</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';
import Title01 from '@/components/Title01.vue';
import {KIJI_TITLE_LIST2024} from "@/kiji/js/titleCONST"

export default {
 components: { 
     HeaderGD,
     GeneralParent,
     MainDisplay,
     Title01,
    },
    data(){
        return{
            KIJI_TITLE_LIST2024: KIJI_TITLE_LIST2024,
        }
    }
};
</script>

  
<style scoped>
@import '@/kiji/css/kijiDefault.css';

</style>