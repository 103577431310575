<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>
            <Title01>{{KIJI_TITLE_LIST2024.KIJI002}}</Title01>
            <p class="kiji-default-p">
                ビールと共にある、アダタラです。
            </p>
            <p class="kiji-default-p">
                GWなので那須に行ってきました。ただ、この旅行で撮影した全4枚の写真がどうにも那須観光感が薄いので、わざわざ那須に赴いた旨を宣言しています。
            </p>
            <p class="kiji-default-p">
                今回尋ねた施設は以下の通りです。
            </p>
            <ul>
                <li><a href="https://www.senbonmatsu.com/">千本松牧場</a></li>
                <li><a href="https://www.epinard.jp/">ホテルエピナール那須</a></li>
                <li><a href="https://spa-ya.net/">宇都宮スパ屋</a></li>
            </ul>
            <p class="kiji-default-p">
                千本松牧場で昼ごはん、ホテルエピナール那須で日帰り温泉、宇都宮スパ屋でスパゲッティを頂いたはずなんですけど、、
            </p>
            <img class="kiji-default-img add-img" alt="那須のクラフトビール" src="./img/20240503/IMG_3286.jpg"/>
            <p class="kiji-default-p">
                那須のクラフトビール、「なすですな」。軽やかな苦味で呑みやすかったかも。
            </p>
            <img class="kiji-default-img add-img" alt="那須のクラフトビール" src="./img/20240503/IMG_3287.jpg"/>
            <p class="kiji-default-p">
                ホテルエピナール那須の駐車場の奥にあった散歩道の行き止まり。アプデ入ったら新エリア開放されそう。
            </p>
            <img class="kiji-default-img add-img" alt="那須のクラフトビール" src="./img/20240503/IMG_3288.jpg"/>
            <p class="kiji-default-p">
                那須高原今牧場で作られている「熟成チーズ なすの」。口に含むと瞬間にチーズが解けて、パっと広がる爽やかな酸味とチーズのうまみがビールによく合います。程よい塩気も効いていて、鼻から抜ける香りが海の潮風のようでした。カマンベールチーズのような濃厚でクリーミーな味わいとは対照的なチーズで、飲み歩きにちょうどよい一品でした。炭の中で熟成させたということで、チーズの周りが黒く色づいていて、見た目の面白さも素晴らしいです。
            </p>
            <p class="kiji-default-p">
                これは本当においしかったのでリンク貼っておきます↓
            </p>
            <p class="kiji-default-p"><a href="https://imafarm.xsrv.jp/product/%e7%86%9f%e6%88%90%e3%83%81%e3%83%bc%e3%82%ba-%e3%81%aa%e3%81%99%e3%81%ae/">熟成チーズ なすの</a></p>
            <img class="kiji-default-img add-img" alt="那須のクラフトビール" src="./img/20240503/IMG_3289.jpg"/>
            <p class="kiji-default-p">
                宇都宮スパ屋で頼んだMEGAビール。メガジョッキで来るかと思いきや、まさかのでっかいカクテルグラスみたいな姿で登場。インパクト強すぎて味はあんまり覚えてないんですけど美味しかったと思います。トマトソースとチーズのかかった餃子も最高でした。スパゲッティもたぶん美味しかった気がします。
            </p>
            <p class="kiji-default-p add-last-p">
                以上那須観光でした。というか千本松牧場の写真撮ってないの？
            </p>
        </MainDisplay>

    </GeneralParent>

</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';
import Title01 from '@/components/Title01.vue';
import {KIJI_TITLE_LIST2024} from "@/kiji/js/titleCONST"

export default {
 components: { 
     HeaderGD,
     GeneralParent,
     MainDisplay,
     Title01,
    },
    data(){
        return{
            KIJI_TITLE_LIST2024: KIJI_TITLE_LIST2024,
        }
    }
};
</script>

  
<style scoped>
@import '@/kiji/css/kijiDefault.css';
.add-last-p{
    margin-top: 1rem;
}
.add-img{
    max-height: 600px;
    width:auto;
}
</style>