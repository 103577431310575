<template>
    <GeneralParent>
        <HeaderGD/>
        <MainDisplay>
            <Title01>Webアプリのトップページです</Title01>
            <p><router-link to="/apps/KnightTour">旅するナイト</router-link></p>
        </MainDisplay>

    </GeneralParent>
</template>

<script>
import GeneralParent from '@/components/GeneralParent.vue';
import HeaderGD from '@/components/HeaderGD.vue';
import MainDisplay from '@/components/MainDisplay.vue';
import Title01 from '@/components/Title01.vue';

export default {
 components: { 
     HeaderGD,
     GeneralParent,
     MainDisplay,
     Title01,
    }
};
</script>

  
<style scoped>

</style>